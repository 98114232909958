import { createStore } from 'vuex'

function calculateSecondsToMinutes(seconds) {
    return Math.floor(seconds / 60) + ":" + ('0' + Math.floor(seconds % 60)).slice(-2)
}

export default createStore({
    // state is where we store the data
    state: {
        seasonType: '',
        season: '',
        currentSeason : '',
        dataScope: "overall",
        numberOfGames: 5,
        picksData: {},
        playerGameLogs: {},
        teamGameLogs: {},
        warnings: [],
        spinner: true,
        buttonStatus: false,
        error: '',
        listStatus: [ true, true, true],
        warningStatus: true,
        scorers : {},
        yesterdayScorers : {},
        games : []
    },
    getters: {
        // loop gamlogs and do calculation
        getGameLogs: (state) => {
            let newPlayerLists = [];
            let teamGAAs = [];

            state.teamGameLogs.forEach(team => {
                let newTeam = {}
                let numberOfGames = state.numberOfGames;
                if (team.gameLogs.length < numberOfGames) {
                    numberOfGames = team.gameLogs.length
                }

                newTeam.abbreviation = team.abbreviation
                newTeam.goalsAgainst = 0
                for(let i = 0; i < numberOfGames; i++) {
                    newTeam.goalsAgainst += team.gameLogs[i].goalsAgainst
                }

                newTeam.GAA = newTeam.goalsAgainst / numberOfGames
                teamGAAs.push(newTeam);
            });

            state.playerGameLogs.forEach(playerLists => {
                let playerList = {}
                playerList.id = playerLists.id
                playerList.players = []
                playerLists.players.forEach(player => {
                    let newPlayer = {}
                    newPlayer.nhlPlayerId = player.nhlPlayerId
                    newPlayer.firstName = player.firstName
                    newPlayer.lastName = player.lastName
                    newPlayer.position = player.position
                    newPlayer.team = player.team
                    newPlayer.opponentTeam = player.opponentTeam
                    newPlayer.unavailable = player.unavailable
                    newPlayer.homeAway = player.homeAway
                    newPlayer.goals = 0
                    newPlayer.shots = 0
                    newPlayer.timeOnIcePerGame = 0
                    newPlayer.powerPlayTimeOnIce = 0
                    newPlayer.shotsPerGame = 0
                    newPlayer.goalsPerGame = 0
                    newPlayer.shootingPercentage = 0
                    newPlayer.timeOnIcePerGame = 0
                    newPlayer.powerPlayTimeOnIcePerGame = 0
                    newPlayer.opponentGAA = 0
                    newPlayer.line = player.line
                    newPlayer.ppLine = player.ppLine

                    let numberOfGames = state.numberOfGames
                    if (player.gameLogs.length < numberOfGames) {
                        numberOfGames = player.gameLogs.length
                    }
                    for(let i = 0; i < numberOfGames; i++) {
                        newPlayer.goals += player.gameLogs[i].goals
                        newPlayer.shots += player.gameLogs[i].shots
                        newPlayer.timeOnIcePerGame += player.gameLogs[i].timeOnIce
                        newPlayer.powerPlayTimeOnIce += player.gameLogs[i].powerPlayTimeonIce
                    }


                    newPlayer.gamesPlayed = numberOfGames
                    if (numberOfGames != 0) {
                        newPlayer.shotsPerGame = newPlayer.shots / newPlayer.gamesPlayed
                        newPlayer.goalsPerGame = newPlayer.goals / newPlayer.gamesPlayed
                        newPlayer.shootingPercentage = (newPlayer.goals / newPlayer.shots) * 100
                        newPlayer.timeOnIcePerGame = calculateSecondsToMinutes(newPlayer.timeOnIcePerGame / newPlayer.gamesPlayed)
                        newPlayer.powerPlayTimeOnIcePerGame = calculateSecondsToMinutes(newPlayer.powerPlayTimeOnIce / newPlayer.gamesPlayed)
                    }

                    let team = teamGAAs.find(t => t.abbreviation === newPlayer.opponentTeam)
                    if (team) {
                        newPlayer.opponentGAA = team.GAA
                    }
                    else {
                        newPlayer.opponentGAA = 'N/A'
                    }

                    playerList.players.push(newPlayer)

                });
                newPlayerLists.push(playerList)
            });

            return newPlayerLists;
        },
        getListStatus:(state) => (listIndex) => {
            const index = listIndex -1
            return state.listStatus[index];
        },
        getWarningStatus: (state) => {
            return state.warningStatus;
        },
        getSeasons: (state) => {
            let seasons = [];
            let currentSeasons = {}
            let previousSeason = {}
            
            const currentSeasonOne = state.currentSeason.substring(0,4)
            const currentSeasonTwo = state.currentSeason.substring(4)
            const currentSeasonFormat = currentSeasonOne + " - " + currentSeasonTwo
            currentSeasons.formatted = currentSeasonFormat
            currentSeasons.unformatted = state.currentSeason

            const previousSeasonOne = currentSeasonOne-1
            const previousSeasonTwo = currentSeasonTwo-1
            const previousSeasonFormat = previousSeasonOne + " - " + previousSeasonTwo
            const unformattedPreviousSeason = previousSeasonOne + "" + previousSeasonTwo
            previousSeason.formatted = previousSeasonFormat
            previousSeason.unformatted = unformattedPreviousSeason

            seasons.push(currentSeasons)
            seasons.push(previousSeason)

            return seasons;
        },
        getScorers: (state) => {
            return state.scorers;
        },
        getYesterdayScorers: (state) => {
            return state.yesterdayScorers;
        },
        getGames: (state) => {
            return state.games;
        }
    },
    mutations: {
        setSeasonType(state, seasonType) {
            state.seasonType = seasonType
        },
        setSeason(state, season) {
            state.season = season
        },
        setCurrentSeason(state, season){
            state.currentSeason = season
        },
        setDataScope(state, dataScope) {
            state.dataScope = dataScope
        },
        setWarnings(state, warnings) {
            state.warnings = warnings
        },
        minusNumberOfGames(state) {
            state.numberOfGames --
        },
        plusNumberOfGames(state) {
            state.numberOfGames ++
        },
        // for user typing number in the input box
        setNumberOfGames(state, numberOfGames) {
            state.numberOfGames = numberOfGames
        },
        // picks data
        setPicksData(state, picksData) {
            state.picksData = picksData
        },
        // gamelogs data
        setGameLogs(state, gemeLogs) {
            state.playerGameLogs = gemeLogs
        },
        setTeamGameLogs(state, gameLogs){
            state.teamGameLogs = gameLogs
        },
        setScorers(state, scorers) {
            state.scorers = scorers
        },
        setYesterdayScorers(state, yesterdayScorers) {
            state.yesterdayScorers = yesterdayScorers
        },
        setGames(state, games) {
            state.games = games
        },
        setSpinner(state, spinner) {
            state.spinner = spinner
        },
        setButtons(state, button) {
            state.buttonStatus = button
        },
        setErrorStatus(state, error) {
            state.error = error
        },
        setListStatus(state, listStatus) {
            //state.listStatus = listStatus
            const index = listStatus -1
            state.listStatus[index] = !state.listStatus[index];
        },
        setWarningStatus(state) {
            state.warningStatus = !state.warningStatus
        }
    },
    actions: {
        // handle filter menu click
        setSeasonType(context, payload) {
            context.commit('setSeasonType', payload)
        },
        setSeason(context, payload) {
            context.commit('setSeason', payload)
        },
        setDataScope(context, payload) {
            context.commit('setDataScope', payload)
        },
        minusNumberOfGames(context) {
            context.commit('minusNumberOfGames')
        },
        plusNumberOfGames(context) {
            context.commit('plusNumberOfGames')
        },
        // for user typing number in the input box
        setNumberOfGames(context, payload) {
            context.commit('setNumberOfGames', payload)
        },
        setSpinner(context, payload) {
            context.commit('setSpinner', payload)
        },
        setButtons(context, payload) {
            context.commit('setButtons', payload)
        },
        setErrorStatus(context, payload) {
            context.commit('setErrorStatus', payload)
        },
        setListStatus(context, payload) {
            context.commit('setListStatus', payload)
        },
        setWarningStatus(context) {
            context.commit('setWarningStatus')
        },
        // fetch picks data
        getPicks(context) {
            let url = "https://api.hockeychallengehelper.com/api/picks?";
            // let backupurl = "https://hockeychallengehelperbackup.azurewebsites.net/api/picks?"
            if (this.state.season){
                url += "season=" + this.state.season + "&"
                // backupurl += "season=" + this.state.season + "&"
            }
            if (this.state.seasonType){
                url += "seasonType=" + this.state.seasonType
                // backupurl += "seasonType=" + this.state.seasonType
            }
            return fetch(url)
                .then(response => {
                    if (!response.ok) {
                        console.log(response)
                        if (response.status === 404){
                            response.text().then(data =>{
                                console.log('404 ' + data)
                                context.commit("setErrorStatus", data)
                            })
                        }
                        else{
                            context.commit("setErrorStatus", response.status)
                        }

                        context.commit('setSpinner', false)
                        context.commit('setButtons', false)
                    }
                    else return response.json()
                })
                .then(data => {
                    // set... goes to mutations
                    context.commit("setSeasonType", data.seasonType)
                    context.commit("setSeason", data.season)
                    if (!this.state.currentSeason) {
                        context.commit('setCurrentSeason', data.season)
                    }
                    context.commit("setPicksData", data.playerLists)
                    context.commit("setWarnings", data.warnings)
                    context.commit("setErrorStatus", "200")
                    context.commit('setSpinner', false)
                    context.commit('setButtons', false)
                })
        },
        // fetch gamelogs data
        getGameLogs(context) {
            let url = "https://api.hockeychallengehelper.com/api/gamelogs?";
            // let backupurl = "https://hockeychallengehelperbackup.azurewebsites.net/api/gamelogs?"
            if (this.state.season){
                url += "season=" + this.state.season + "&"
                // backupurl += "season=" + this.state.season + "&"
            }
            if (this.state.seasonType){
                url += "seasonType=" + this.state.seasonType
                // backupurl += "season=" + this.state.season + "&"
            }
            return fetch(url)
                .then(response => {
                    if(!response.ok) {
                        console.log(response)
                        if (response.status === 404){
                            response.text().then(data =>{
                                console.log('404 ' + data)
                                context.commit("setErrorStatus", data)
                            })
                        }
                        else{
                            context.commit("setErrorStatus", response.status)
                        }

                        context.commit('setSpinner', false)
                        context.commit('setButtons', false)
                    }
                    else return response.json()
                })
                .then(data => {
                                // setGameLogs goes to mutations
                    // context.commit("setErrorStatus", "200")
                    context.commit("setGameLogs", data.playerLists);
                    context.commit("setTeamGameLogs", data.teamLogs)
                    context.commit('setButtons', false)
                })
        },
        getLiveStats(context) {
            let url = "https://api.hockeychallengehelper.com/api/live";
            // let backupurl = "https://hockeychallengehelperbackup.azurewebsites.net/api/live"
            return fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(response.status)
                    }
                    else {
                        context.commit('setSpinner', true)
                        return response.json()
                    }
                })
                .then(data => {
                    context.commit("setErrorStatus", "200")
                    context.commit("setScorers", data.scorers)
                    context.commit("setYesterdayScorers", data.yesterdayScorers)
                    context.commit("setGames", data.games)
                    context.commit('setSpinner', false)
                })
                .catch(err => {
                    context.commit("setErrorStatus", err.message)
                    context.commit('setSpinner', false)
                })
        }
    },
    modules: {
    }
})